const profileImages = [
    'https://image.fnbr.co/outfit/5ab155400585dc38d8138e51/icon.png',
    'https://image.fnbr.co/outfit/5ab158dfe9847b3170da0331/icon.png',
    'https://image.fnbr.co/outfit/5ae893d496f7045898e909b6/icon.png',
    'https://image.fnbr.co/outfit/5ae8940e96f704fecbe909bb/icon.png',
    'https://image.fnbr.co/outfit/5b47d502582d6fa10ca1d197/icon.png',
    'https://image.fnbr.co/outfit/5bae0a3143fc981b776770fd/icon.png',
    'https://image.fnbr.co/outfit/5bae0a6243fc98bdb1677101/icon.png',
    'https://image.fnbr.co/outfit/5da5d7eb377bc57f1796e5cf/icon.png',
    'https://image.fnbr.co/outfit/5c77c441626179144849f0b4/icon.png',
    'https://image.fnbr.co/outfit/5da5d7c9377bc55ec796e5cc/icon.png',
    'https://image.fnbr.co/outfit/62b9ca30a6f360fad555c1dc/icon.png',
    'https://image.fnbr.co/outfit/5ab179f65f957f27504aa528/icon.png'
]

export default profileImages;